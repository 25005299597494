<template>
    <div class="card shadow">
        <div class="card-body">
            <export-component />

            <TransitionGroup name="list" tag="div">
                <div class="py-2 px-1 border-bottom d-flex align-items-center" 
                    v-for="(item, i) in store.items" 
                    :key="`each-item-${i}`">

                    <div class="icon-type bg-light mx-2 rounded small text-muted">
                        <user-avatar-badge :name="item.operator || 'Unknown'" />
                    </div>

                    <div class="mx-1">
                        <p class="mb-0">{{ item.id }} - 
                            <span>
                                {{ item.carrier ? item.carrier.data.name : "Transportista no especificado" }}
                            </span> 
                            - 
                            {{ item.operator ? item.operator : 'Operador no especificado'  }}
                        </p>
                        <p class="small text-muted mb-0">
                            <span><i class="bi bi-truck"></i> {{ item.out_weight - item.in_weight }}kg</span>
                            <span class="ms-2"><i class="bi bi-calendar-event"></i> {{ dateFormatted(item.created_at) }}</span>
                            <span class="ms-2" v-if="item.work_ref"><i class="bi bi-upc-scan"></i> {{ item.work_ref }}</span>
                            <span class="ms-2" v-if="item.product"><i class="bi" :class="item.product?.data?.type === 'input' ? 'bi-arrow-right' : 'bi-arrow-left'"></i> {{ getType(item.product?.data?.type) }}</span>
                        </p>
                    </div>

                    <div class="ms-auto text-end">
                        <span 
                            v-if="item.carrier_id === null || item.customer_id === null || item.vehicle_id === null || item.work_id === null || !item.weight || !item.ler" 
                            class="me-3 p-1 shadow rounded text-white bg-danger small">
                            Incompleto</span>

                        <button-icon icon="bi bi-eye" route="receipts-edit" :params="{id: item.id}" />
                        <button-icon-submit text="Albarán" :disabled="downloading" icon="bi bi-download" @on-submit="download(item.id, 'receipt')" />
                        <button-icon-submit v-if="item.type === 'input'" text="Seguimiento" :disabled="downloading" icon="bi bi-download" @on-submit="download(item.id, 'tracking')" />
                        <button-icon-submit icon="bi bi-x" @on-submit="handleDelete(item.id)"/>
                        
                    </div>
                </div>

                <div v-if="store.items.length <= 0" class="py-5 text-center text-muted" key="no-results">
                    <i class="bi bi-funnel h2"></i>
                    <p>No se han encontrado resultados</p>
                </div>
            </TransitionGroup>

            <div ref="scrollEnd" class="text-center mt-3">
                <span v-if="store.isLoadingMore">Cargando más...</span>
                <button v-else class="btn btn-outline-danger" @click="loadMore">Cargar más albaranes</button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { dateFormatted } from "@/helpers/moment";
import { ref, onMounted, onBeforeUnmount } from "vue";
import { swalToast } from "@/helpers/swal";
import { useReceiptStore } from "@/stores/receipt";
import ButtonIcon from "@/components/common/buttons/ButtonIcon.vue";
import ButtonIconSubmit from "../common/buttons/ButtonIconSubmit.vue";
import PaginationNavComponent from '@/components/common/PaginationNavComponent.vue'
import server from "@/api/server";
import UserAvatarBadge from "@/components/users/UserAvatarBadge.vue";
import ExportComponent from "@/components/receipts/ExportComponent.vue";


const store = useReceiptStore();
const downloading = ref(false)
const serverURL = process.env.VUE_APP_SERVER_BASE_URL
const observer = ref(null);
const scrollEnd = ref(null);

const handleDelete = async(id) => {
    await store.destroy(id)
}

const getType = (type) => {
    if(type === 'input')
        return 'Entrada de residuos'
    if(type === 'output')
        return 'Salida de residuos'
    if(type === 'output_arids')
        return 'Salida de áridos'
}

const download = async(id, type) => {
    try {
        downloading.value = true

        const action = type === 'receipt'
            ? `/receipts/download/${id}`
            : `/receipts/download/waste-tracking/${id}`

        const { data } = await server.get(action, {
            Accept: 'application/pdf',
        })

        if(!data.status)
            return;

        window.open(`${serverURL}/${data.message}`);
    } catch(err) {
        console.log('[ERR] TableComponent.vue', err)
        swalToast(err, "error")
    } finally {
        downloading.value = false
    }
}

const loadMore = async () => {
    if (store.isLoadingMore || store.isLoading || store.searchInput !== '') return;
    const currentPage = ++store.page

    try {
        await store.fetch(currentPage);
        store.setPage(currentPage);
    } catch (err) {
        console.error("Error loading more items:", err);
    }
};

onMounted(() => {
    observer.value = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
            loadMore();
        }
    });

    if (scrollEnd.value) {
        observer.value.observe(scrollEnd.value);
    }
});

onBeforeUnmount(() => {
    if (observer.value && scrollEnd.value) {
        observer.value.unobserve(scrollEnd.value);
    }
});
</script>