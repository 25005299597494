import { defineStore } from 'pinia'
import { ref, watch } from 'vue'
import { swalToast, swalQuestionCustom } from '../helpers/swal'
import server from '../api/server'

export const useReceiptStore = defineStore('receiptStore', () => {
    const endpoint = '/receipts'
    const isLoading = ref(false)
    const isLoadingMore = ref(false)
    const items = ref([])
    const orderFilter = ref('asc')
    const page = ref(1)
    const searchInput = ref('')

    watch(searchInput, async (newVal) => {
        if(newVal === '')
            return fetch();

        if (newVal.length > 2) {
            search(newVal)
        }
    })

    async function search(query) {
        try {
            const { data } = await server.get(`${endpoint}/search?query=${query}&include=vehicle,customer,product,carrier`)
            const { status, results } = data

            if(status)
                items.value = results.data
        } catch (error) {
            console.log('Error', error)
        }
    }

    function setPage(value){
        page.value = value
    }

    function filter(event){
        const field = event.target.value
        items.value.sort((a, b) => (a[field] < b[field]) ? -1 : 1)
    }

    function sortDirection(arg){
        orderFilter.value = arg
        items.value.reverse()
    }

    async function getItem(id){
        return (id != 'new') ? await getById(id) : { id: 'new' }
    }

    async function fetch(p = 1) {
        try {
            if(p === 1) {
                isLoading.value = true
            } else {
                isLoadingMore.value = true
            }

            const { data } = await server.get(`${endpoint}?page=${p}&include=carrier,product`)
            const { status, results } = data

            if(status)
                items.value = p === 1 ? results.data : [...items.value, ...results.data]
        } catch(err) {
            swalToast(err.message, 'error')
        } finally {
            isLoading.value = false
            isLoadingMore.value = false
        }
    }

    async function getById(id) {
        try {
            isLoading.value = true
            const { data } = await server.get(`${endpoint}/${id}?include=vehicle,carrier,product,customer,work,discharge`)
            const { status, results } = data

            if(status)
                return results.data
        } catch(err) {
            swalToast(err.message, 'error')
            return null
        } finally {
            isLoading.value = false
        }
    }

    async function submitForm(payload) {
        try {
            const action = (payload.id === 'new') ? endpoint : `${endpoint}/update`
            const { data } = await server.post(action, payload)

            swalToast(data.message)
            return data.status;
        } catch(err) {
            swalToast(!err.response.data.errors ? err.message : Object.values(err.response.data.errors)[0], 'error')
            return false
        }
    }

    async function submitNewCustomer(payload) {
        try {
            const { data } = await server.post(`${endpoint}/new-customer`, payload)

            swalToast(data.message)
            return data.status;
        } catch(err) {
            swalToast(!err.response.data.errors ? err.message : Object.values(err.response.data.errors)[0], 'error')
            return false
        }
    }
    
    async function destroy(id) {
        const confirmed = await swalQuestionCustom("¿Deseas eliminar este albarán?", "Una vez eliminado no podrá recuperarse.", "Eliminar", "Cancelar", "warning")
        if(!confirmed) return;

        try {
            const { data } = await server.delete(`${endpoint}/${id}`) 

            const idx = items.value.findIndex(el => el.id === id)
            items.value.splice(idx, 1)
            
            swalToast(data.message)
            return data.status
        } catch(err) {
            swalToast(err.message, 'error')
            return false
        }
    }

    return { 
        isLoading,
        isLoadingMore,
        orderFilter,
        page,
        searchInput,
        items,
        setPage,
        destroy,
        filter,
        getItem,
        fetch, 
        sortDirection, 
        submitForm,
        submitNewCustomer,
    }
})
